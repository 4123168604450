import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import { useHistory, useParams, useLocation } from "react-router-dom";
import useItemsStore from "stores/items";

const BrandItems = () => {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const history = useHistory();
  const items = useItemsStore((state) => state.brandItems[id] ?? []);

  const handleItemClick = (itemNumber: string) => {
    history.push(`/item/${itemNumber}${search}`);
  };

  return (
    <Layout>
      <TotalNum>{`${items?.length ?? "0"}개의 상품`}</TotalNum>
      <Items>
        {items.map((item) => {
          return (
            <ItemWrapper key={item.no} onClick={() => handleItemClick(item.no)}>
              <ItemImage src={item.imagePath} alt={item.name} />
              <ItemInfoWrapper>
                <ItemName>{item.name}</ItemName>
                <ItemPrice>{item.price.toLocaleString()}원</ItemPrice>
              </ItemInfoWrapper>
            </ItemWrapper>
          );
        })}
      </Items>
    </Layout>
  );
};

export default BrandItems;

const TotalNum = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 16px;
  height: 44px;
  background-color: white;
  margin-top: 58px;

  color: var(--Gray-05, #2b2e33);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 23.8px */
  letter-spacing: -0.5px;
`;

const Items = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding-top: 8px;
`;

const ItemWrapper = styled.li`
  display: flex;
  gap: 24px;
  padding: 12px 16px;
  background-color: white;

  cursor: pointer;
`;

const ItemImage = styled.img`
  flex-shrink: 0;
  width: 84px;
  height: 84px;
`;

const ItemInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
`;

const ItemName = styled.p`
  color: var(--Gray-05, #2b2e33);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 23.8px */
  letter-spacing: -0.5px;
`;

const ItemPrice = styled.p`
  color: var(--Gray-05, #2b2e33);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: -0.5px;
`;
