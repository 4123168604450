import axios from "axios";
import queryString from "query-string";

const query = queryString.parse(window.location.search);
export const userToken = query.user ?? window.localStorage.getItem("userID");

let env;
let talkbot;
// REST API
const currentURL = window.location.href;
declare global {
  interface Document {
    documentMode?: any;
  }
  interface Window {
    $env: string;
    $redirectUri: string;
    $baseURL: string;
    $talkbot: string;
  }
}
if (
  currentURL.startsWith("https://panelmall.pocketsurvey.co.kr/") ||
  currentURL.startsWith("https://www.panelmall.pocketsurvey.co.kr/")
) {
  env = "prod";
  talkbot = "@pocketsurvey";
} else {
  env = "dev";
  talkbot = "@zxcxz7estest";
}

env = "prod";
talkbot = "@pocketsurvey";

window.$env = env;
window.$talkbot = talkbot;

export const API = `https://hac15rl0qa.execute-api.ap-northeast-2.amazonaws.com/${window.$env}/mall`;
// `https://panelmall.earlysloth.com` == env > prod
// localhost:43000 == env > dev

axios.defaults.headers.common["x-api-username"] = userToken;
axios.defaults.headers.common["cache-control"] = "no-cache";
