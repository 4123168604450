import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

import Layout from "../../components/Layout/Layout";
import UserInfo from "./components/UserInfo";
import QuestionList from "./components/QuestionList";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import useItemsStore from "stores/items";

const Main = () => {
  const history = useHistory();
  const location = useLocation();
  const brandList = useItemsStore((state) => state.brandList);

  const handleBrandClick = (brand: string) => {
    history.push(`/brand-items/${brand}${location.search}`);
  };

  return (
    <Layout isMainPage>
      <Contents>
        <UserInfo />
      </Contents>

      <>
        {Object.entries(brandList).length !== 0 ? (
          Object.entries(brandList).map(([category, brands]) => {
            return (
              <CategoryWrapper key={category}>
                <CategoryName>{category}</CategoryName>
                <ItemLists>
                  {brands.map((brand) => {
                    return (
                      <Item key={brand.name} onClick={() => handleBrandClick(brand.name)}>
                        <ItemWrapper>
                          <ItemImage src={brand.imageURL} alt={brand.name} />
                          <ItemName>{brand.name}</ItemName>
                        </ItemWrapper>
                      </Item>
                    );
                  })}
                </ItemLists>
              </CategoryWrapper>
            );
          })
        ) : (
          <CategoryWrapper>
            <Skeleton width={50} height={20} style={{ marginBottom: 12 }} />
            <ItemLists>
              {[...Array(9)].map((_, index) => {
                return (
                  <Item key={index}>
                    <ItemWrapper>
                      <Skeleton width={41} height={25} />
                      <Skeleton width={59} height={12} />
                    </ItemWrapper>
                  </Item>
                );
              })}
            </ItemLists>
          </CategoryWrapper>
        )}
      </>

      <QuestionList />
    </Layout>
  );
};

export default Main;

const Contents = styled.div`
  margin-top: 3.6em;
  padding: 20px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray01};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CategoryWrapper = styled.div`
  padding: 12px 20px 20px;
`;

const CategoryName = styled.p`
  padding-bottom: 12px;

  color: var(--Gray-05, #2b2e33);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.5px;
`;

const ItemLists = styled.ul`
  display: grid;

  grid-template-columns: repeat(3, 1fr);

  gap: 8px;
`;

const Item = styled.li`
  display: flex;
  background-color: white;
  border-radius: 6px;
  cursor: pointer;

  &::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: calc(100%);
  }
`;

const ItemWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 20px 0 12px;
`;

const ItemImage = styled.img`
  height: 35px;
`;

const ItemName = styled.p`
  color: var(--Gray-05, #2b2e33);
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 23.8px */
  letter-spacing: -0.5px;
`;
