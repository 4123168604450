import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";
import { getItemCategories, changeCategoryImage } from "../lib/api";
import { AxiosResponse } from "axios";
import { CategoryType } from "./types";

export type MainStateType = {
  itemCategories: CategoryType[] | null;
  isLoading: boolean;
};

const mainInitialState: MainStateType = {
  itemCategories: null,
  isLoading: false,
};

const mainSlice = createSlice({
  name: "main",
  initialState: mainInitialState,
  reducers: {
    getItemCategories() {},
    setItemCategories(state, action: any) {
      state.itemCategories = action.payload;
    },
    setIsLoading(state, action: any) {
      state.isLoading = action.payload;
    },
  },
});

export const mainActionsCreators = {
  ...mainSlice.actions,
};

function* getItemCategoriesWorker() {
  try {
    mainActionsCreators.setIsLoading(true);
    // const rawCategories: CategoryType[] = yield call(getItemCategories); // 니콘내콘에서 사용하는 카테고리 이미지 정보가 담겨있다.
    const rawCategories: CategoryType[] = [
      {
        id: 123,
        name: "tony-test",
        imageUrl:
          "https://images.unsplash.com/photo-1707045130985-35f4f74235c0?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fHx8",
        brands: [
          {
            id: 300,
            name: "tony-test-inner",
            imageUrl:
              "https://images.unsplash.com/photo-1707045130985-35f4f74235c0?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fHx8",
            categoryId: 400,
            brandItems: [
              {
                id: 500,
                name: "tony-test-inner-inner",
                imageUrl:
                  "https://images.unsplash.com/photo-1707045130985-35f4f74235c0?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fHx8",
                originalPrice: 10000,
                minSellingPrice: 900,
              },
            ],
          },
        ],
      },
    ];
    const pkCategories: CategoryType[] = yield call(changeCategoryImage, rawCategories); // 포켓서베이에서 사용하는 이미지 정보로 변환한다.
    console.log("pkCategories: ", pkCategories);
    yield put({
      type: "main/setItemCategories",
      payload: pkCategories,
    });
    mainActionsCreators.setIsLoading(false);
  } catch (e) {
    console.error(e);
  }
}

export function* mainSaga() {
  const { getItemCategories } = mainActionsCreators;
  yield takeLatest(getItemCategories, getItemCategoriesWorker);
}

export const main = mainSlice.reducer;
