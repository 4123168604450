import styled from "styled-components";
import Nav from "./Nav";
import Footer from "./Footer";
import Loading from "../Loading";

interface ILayoutProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
  isMainPage?: boolean;
  isLoading?: boolean;
}

const Layout = ({ children, className, isMainPage = false, isLoading }: ILayoutProps): JSX.Element => (
  <LayoutWrapper className={className ?? ""}>
    <Loading isLoading={isLoading} />
    <Nav />
    {children}
    {isMainPage && <Footer />}
  </LayoutWrapper>
);

const LayoutWrapper = styled.div`
  position: relative;
  max-width: 360px;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.gray01};

  &.of_h {
    overflow: hidden;
  }

  @media (max-width: 42rem) {
    width: 100%;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export default Layout;
