import React from "react";
import styled from "styled-components";

const ExchangeButton = () => {
  return (
    <Container>
      <Button>교환하기</Button>
    </Container>
  );
};

export default ExchangeButton;

const Container = styled.div`
  position: fixed;
  bottom: 0;

  padding: 16px;

  width: 100%;
  max-width: 360px;

  border-radius: 4px 4px 0px 0px;
  background: #fff;
  box-shadow: 1px 0px 11px 1px rgba(0, 0, 0, 0.36);
`;

const Button = styled.button`
  width: 100%;
  height: 48px;
  padding: 0 32px;

  border-radius: 3px;
  border: 1px solid var(--Light-Yellow-01, #fdedbd);
  background: var(--light-yellow-05, #fef4ce);

  color: var(--Beige-03, #d2cbc0);
  text-align: center;

  /* Button-L-16-Medium */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  letter-spacing: -0.5px;
`;
