import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { PkLogo } from "../../assets";

const Nav = () => {
  const history = useHistory();
  const { search } = useLocation();
  return (
    <NavWrapper>
      <Logo src={PkLogo} onClick={() => history.push(`/${search}`)} />
    </NavWrapper>
  );
};

export default Nav;

const NavWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 0px 16px;
  height: 55px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
`;

const Logo = styled.img`
  width: 143px;
  height: 23px;

  cursor: pointer;
`;
