import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import queryString from "query-string";
import CategoriesContainer from "./redux/containers/CategoriesContainer";
import NotFound from "./components/NotFound/NotFound";
import Main from "pages/Main/Main";
import BrandItems from "pages/BrandItems/BrandItems";
import { useMount } from "react-use";
import useUserStore from "stores/user";
import useItemsStore from "stores/items";
import Item from "pages/Item/Item";

const query = queryString.parse(window.location.search);
const userId = query.user;

if (userId) {
  window.localStorage.setItem("userID", userId);
} else {
  window.confirm("비정상 접근입니다.");
}

const Routes = () => {
  useMount(() => {
    useUserStore.getState().initUser();
    useItemsStore.getState().initItems();
  });

  return (
    <Router>
      {userId ? (
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/categories/:id" component={CategoriesContainer} />
          <Route exact path="/brand-items/:id" component={BrandItems} />
          <Route exact path="/item/:id" component={Item} />
          <Route path="*" component={NotFound} />
        </Switch>
      ) : (
        <NotFound />
      )}
    </Router>
  );
};

export default Routes;
