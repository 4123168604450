import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, takeEvery } from "redux-saga/effects";
import * as api from "../lib/api"
import { AxiosResponse } from 'axios';
import { OptionType, BrandItemType } from "./types";
import postOrderSaga from "../lib/postOrderSaga";

function* getSelectedItemSaga(action: any) {
  let data: AxiosResponse = yield call(api.getItem, action.payload)
  yield put({
    type: "items/getSelectedItemSuccess",
    payload: data.data.conItem,
  });

  const itemOptions = data.data.conItem.options
  if (itemOptions.length > 0) {
    yield put({
      type: "items/putSelectedOption",
      payload: itemOptions[0] // 가장 유효기간이 짧은 것
    })
  }
}

export function* itemsSaga() {
  const { getSelectedItem, postOrderValue } = itemsActionsCreators;
  yield takeEvery(getSelectedItem, getSelectedItemSaga);
  yield takeLatest(postOrderValue, postOrderSaga);
}

export type PurchasedItemType = {
  info: string,
  expireAt: string,
  reducedPoints: number,
  conUrl: string,
  barcodeNumber: number,
}

export type ItemStateType = {
  selectedItem: BrandItemType | null;
  selectedOptionValue: OptionType | "loading" | null;
  showSuccessPopup: boolean;
  purchasedItem: PurchasedItemType | null;
};

const initialState: ItemStateType = {
  selectedItem: null,
  selectedOptionValue: "loading",
  showSuccessPopup: false,
  purchasedItem: null,
};

const itemsSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    getSelectedItem(state, action:PayloadAction<string>){},
    getSelectedItemSuccess(state, action:PayloadAction<BrandItemType>){
      state.selectedItem = action.payload;
    },
    putSelectedOption(state, action:PayloadAction<OptionType | null>){
      state.selectedOptionValue = action.payload;
    },
    postOrderValue(){},
    setSuccessPopup(state, action: PayloadAction<boolean>){
      state.showSuccessPopup = action.payload
    },
    setPurchasedItem(state, action: PayloadAction<PurchasedItemType>){
      state.purchasedItem = action.payload
    }
  }
});

export const itemsActionsCreators = {...itemsSlice.actions};

export const items = itemsSlice.reducer;
