import { createWithEqualityFn } from "zustand/traditional";
import { combine } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import axios, { AxiosError } from "axios";
import { API } from "config";

const initialState: {
  userToken: string;
  userName: string;
  surveyed: number;
  points: number;
} = {
  userToken: "",
  userName: "",
  surveyed: 0,
  points: 0,
};

const useUserStore = createWithEqualityFn(
  immer(
    combine(initialState, (set, get) => ({
      reset: () => {
        set(initialState);
      },

      initUser: async () => {
        try {
          const data = await axios.get(`${API}/authorize`);
          const userInfoJson = JSON.parse(data.data.user_info);

          const blackList = ["+82 10-9134-1535"];
          if (blackList.includes(userInfoJson.phone_number) || userInfoJson.email === "sunhye1216@naver.com") {
            window.alert("잘못된 접근으로 이용 정지된 사용자입니다.");
            window.location.href = "http://pf.kakao.com/_BgxbUxl/chat";
            return;
          }

          const { nickname } = userInfoJson.profile;
          const { points, surveyed } = data.data;

          set((state) => {
            state.points = points;
            state.surveyed = surveyed;
            state.userName = nickname;
          });
        } catch (_e) {
          if (_e.isAxiosError) {
            const e: AxiosError = _e;
            const redirectURL = e.response?.data;
            window.location.href = redirectURL;
          }
        }
      },
    })),
  ),
);

export default useUserStore;
