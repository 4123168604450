import { createWithEqualityFn } from "zustand/traditional";
import { combine } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import axios from "axios";
import pako from "pako";

const initialState: {
  brandList: {
    [key: string]: {
      name: string;
      imageURL: string;
    }[];
  };
  brandItems: {
    [key: string]: {
      name: string;
      brand: string;
      no: string;
      price: number;
      expiration: string;
      imagePath: string;
      message: string;
    }[];
  };
} = {
  brandList: {},
  brandItems: {},
};

const useItemsStore = createWithEqualityFn(
  immer(
    combine(initialState, (set, get) => ({
      reset: () => {
        set(initialState);
      },

      initItems: async () => {
        try {
          const data = await Promise.all([
            axios.get("/data/brandItems.json.gz", {
              responseType: "arraybuffer",
            }),
            await axios.get("/data/brandList.json.gz", {
              responseType: "arraybuffer",
            }),
          ]);

          const [brandItems, brandList] = data.map((gzipped) => {
            return JSON.parse(pako.ungzip(gzipped.data, { to: "string" }));
          });

          set((state) => {
            state.brandItems = brandItems;
            state.brandList = brandList;
          });
        } catch (_e) {
          console.error("브랜드 정보를 가져오는데 실패하였습니다.");
        }
      },
    })),
  ),
);

export default useItemsStore;
