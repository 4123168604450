import { PkLogo } from "assets";
import { useEffect } from "react";
import styled from "styled-components";
import ChannelService from "../../pages/Main/components/ChannelService";

const Footer = () => {
  useEffect(() => {
    ChannelService.boot({
      pluginKey: "ab7499a7-2221-4949-9d12-827f6d153771",
      customLauncherSelector: ".custom-button, #chatbot-button",
      hideChannelButtonOnBoot: true,
    });
  }, []);
  return (
    <FooterWrapper>
      <CompanyName>
        <div>포켓서베이</div>
        <Logo src={PkLogo} />
      </CompanyName>
      <Info>
        <div>설문조사 서비스의 새로운 표준을 만듭니다.</div>
        <div>ⓒ2021 주식회사 얼리슬로스</div>
        <div>
          <a
            href="https://home.pocketsurvey.co.kr/privacy2/"
            style={{ color: "#818282", textDecoration: "underline" }}
            target="_blank"
            rel="noreferrer"
          >
            개인정보처리방침
          </a>
        </div>
        <br />
        <div>대표: 이재원</div>
        <div>사업자 등록번호 : 899-86-01102</div>
        <div>통신판매등록번호 : 2020-서울종로-0148호</div>
        <br />
        <div id="chatbot-button" className="custom-button">
          문의하기
        </div>
      </Info>
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled.div`
  width: 100%;
  height: 348px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 28px 20px;
  background-color: ${({ theme }) => theme.colors.gray01};
`;

const CompanyName = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  margin-bottom: 14px;

  color: var(--Gray-05, #2b2e33);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.5px;
`;

const Info = styled.div`
  div {
    line-height: 150%;
    margin: 0.3em 0;
    font-size: 14px;
    font-weight: 400;
    color: #818282;
  }
`;

const Logo = styled.img`
  height: 16px;
`;
