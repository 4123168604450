import styled from "styled-components";

const NotFound = () => {
  return (
    <NotFoundWrapper>
      <Message>비정상 접근입니다.</Message>
    </NotFoundWrapper>
  );
};

export default NotFound;

const NotFoundWrapper = styled.div`
  width: 42rem;
  height: 100vh;
  display: flex;
  margin: 0 auto;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 50;
  background-color: white;
  @media (max-width: 42rem) {
    width: 100%;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Message = styled.div`
  margin-top: 5em;
`;
