import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import {user, userSaga} from "./user";
import { items, itemsSaga } from "./items";
import { main, mainSaga } from "./main";
import { brand, brandSaga } from "./brand";
import { categories, categoriesSaga } from "./categories";

const rootReducer = combineReducers({
  user,
  main,
  categories,
  brand,
  items,
});

export function* rootSaga() {
  yield all([userSaga(), itemsSaga(), mainSaga(), brandSaga(), categoriesSaga()]);
}

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
