import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import ItemCategories from "./component/ItemCategories";
import CategoryBox from "../../components/CategoryBox/CategoryBox";
import { useLocation, useHistory } from "react-router-dom";
import { CategoryStateType } from "../../redux/modules/categories";
import { MainStateType } from '../../redux/modules/main';

const CategoryBoxesPlaceHolder = () => (
  <CategoryBox
    id={null}
    img={null}
    name={null}
    onClick={undefined}
  />
);

type CategoriesPropsType =  MainStateType & CategoryStateType;

const Categories = ({ category, itemCategories, isLoading } : CategoriesPropsType) => {
  let brandsInCategories = category.brands;
  const history = useHistory();
  const { state } = useLocation<{name: string}>();
  if (state.name === "편의점,마트") {
    brandsInCategories = category.brands?.filter((item) => item.name !== "세븐일레븐");
  }
  return (
    <Layout>
      <SubNavWrapper>
        <ItemCategories itemCategories={itemCategories} />
      </SubNavWrapper>
      <CategoryBoxesWrapper>
        {brandsInCategories && !isLoading
          ? brandsInCategories.map((list, idx) => {
              const categoriesToBrands = () =>
                history.push({ pathname: `/brand-items/${list.id}`, state: { id: list.id, name: list.name } });
              return (
                <CategoryBox
                  key={list.id}
                  id={list.id}
                  img={list.imageUrl}
                  name={list.name}
                  onClick={categoriesToBrands}
                />
              );
            })
          : Array.from({ length: 12 }).map((_ , idx) => (
              <CategoryBoxesPlaceHolder key={`CategoryBoxPlaceholder${idx}`}/>
            ))}
      </CategoryBoxesWrapper>
    </Layout>
  );
};

export default Categories;

const SubNavWrapper = styled.div`
  width: 100%;
  height: 2.56em;
  display: flex;
  padding: 0 1.25em;
  margin-top: 3.6em;
  background-color: white;
  overflow-x: scroll;
  border-bottom: 0.1em solid ${({ theme }) => theme.colors.gray02};

  ::-webkit-scrollbar {
    display: none;
  }
`;

const CategoryBoxesWrapper = styled.div`
  max-width: 42em;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-basis: auto;
  flex-wrap: wrap;
  padding: 20px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.gray01};
`;
