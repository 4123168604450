import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import * as api from "../lib/api";
import { AxiosResponse } from "axios";
import { CategoryType } from "./types";

export type CategoryStateType = {
  category: CategoryType;
};

const initialState: CategoryStateType = {
  category: {
    id: null,
    name: "",
    imageUrl: "",
    brands: [],
  },
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState: initialState,
  reducers: {
    getBrandsInItemCategories(state, action: PayloadAction<number>) {},
    setBrandsInItemCategories(state, action) {
      const { conCategory1 } = action.payload;
      state.category.id = conCategory1.id;
      state.category.name = conCategory1.name;
      state.category.imageUrl = conCategory1.imageUrl;
      state.category.brands = conCategory1.conCategory2s;
    },
  },
});

function* getBrandsInItemCategoriesWorker(action: PayloadAction<number>) {
  try {
    yield put({
      type: "main/setIsLoading",
      payload: true,
    });
    const response: AxiosResponse = yield call(api.getBrandsInItemCategories, action.payload);
    yield put({
      type: "categories/setBrandsInItemCategories",
      payload: response,
    });
    yield put({
      type: "main/setIsLoading",
      payload: false,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* categoriesSaga() {
  const { getBrandsInItemCategories } = categoriesActionsCreators;
  yield takeLatest(getBrandsInItemCategories, getBrandsInItemCategoriesWorker);
}

export const categoriesActionsCreators = { ...categoriesSlice.actions };
export const categories = categoriesSlice.reducer;
