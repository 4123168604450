import { css } from "styled-components";

const colors = {
  yellow: "#FAC62D",
  boldYellow: "#F2AB28",
  blue: "#59C4DB",
  boldBlue: "#57ADF2",
  lightBeige: "#FBFAF8",
  gray01: "#F0F0F0",
  gray02: "#DFDEDD",
  gray03: "#818282",
  gray04: "#2B2E33",
  beige: "#E9E1D5",
  boldBeige: "#D2CBC0",
  lightYellow: "#FEF4CE",
  lightBlue: "#DEF3F8",
  red: "#FF5724",
  green: "#59BF51",
};

const preset = {
  typography: {
    heading1: css`
      font-family: "Pretendard";
      font-size: 28px;
      line-height: 150%;
      word-break: keep-all;
    `,
    heading2: css`
      font-family: "Pretendard";
      font-size: 21px;
      line-height: 150%;
      word-break: keep-all;
    `,
    subtitle: css`
      font-family: "Pretendard";
      font-size: 18px;
      line-height: 150%;
      word-break: keep-all;
    `,
    paragraph1: css`
      font-family: "Pretendard";
      font-size: 14px;
      line-height: 150%;
      word-break: keep-all;
    `,
    paragraph2: css`
      font-family: "Pretendard";
      font-size: 12px;
      line-height: 150%;
      word-break: keep-all;
    `,
    number1: css`
      font-family: "Pretendard";
      font-size: 28px;
      line-height: 150%;
    `,
    number2: css`
      font-family: "Pretendard";
      font-size: 18px;
      line-height: 150%;
    `,
    number3: css`
      font-family: "Pretendard";
      font-size: 14px;
      line-height: 150%;
    `,
  },
  flex: {
    flexCenter: css`
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    flexColumn: css`
      display: flex;
      flex-direction: column;
    `,
    flexVerticalCenter: css`
      display: flex;
      align-items: center;
    `,
  },
};

const theme = {
  colors,
  preset,
};

export default theme;

export type Theme = {
  colors: typeof colors;
  preset: typeof preset;
};
