import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import { useParams } from "react-router-dom";
import useItemsStore from "stores/items";
import ExchangeButton from "./components/ExchangeButton";
import dayjs from "dayjs";
import { useMount } from "react-use";

const Item = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const selectedItem = useItemsStore((state) =>
    Object.values(state.brandItems)
      .flat()
      .find((item) => item.no === id),
  );

  const expirationDateText = (() => {
    try {
      if (!selectedItem?.expiration) {
        throw new Error("expiration 정보가 없습니다.");
      }

      const expiration = Number(selectedItem.expiration);
      const targetDate = dayjs()
        .add(expiration - 1, "day")
        .format("YYYY.MM.DD");
      return `${selectedItem.expiration}일 (${targetDate}까지)`;
    } catch {
      return "-";
    }
  })();

  useMount(() => {
    window.scrollTo({ top: 0 });
  });

  return (
    <Layout>
      <RelativeWrapper>
        <Wrapper>
          <ItemImageWrapper>
            <ItemImage src={selectedItem?.imagePath} alt={selectedItem?.name} />
          </ItemImageWrapper>

          <ItemInfoWrapper>
            <ItemBrand>{selectedItem?.brand ?? "-"}</ItemBrand>
            <ItemName>{selectedItem?.name ?? "-"}</ItemName>
            <ItemPrice>{selectedItem?.price.toLocaleString() ?? "-"}원</ItemPrice>
          </ItemInfoWrapper>

          <MoreInfoWrapper>
            <MoreInfoTitle>유효 기간</MoreInfoTitle>
            <MoreInfoContent>{expirationDateText}</MoreInfoContent>
          </MoreInfoWrapper>

          <MoreInfoWrapper>
            <MoreInfoTitle>주의 사항</MoreInfoTitle>
            <MoreInfoContent>{`상품 유효기간은 교환권 발송 시점을 기준으로 표시된 일자만큼 적용되며, 연장이 불가합니다.\n발송당일을 포함하여 유효기간이 시작되며, 유효기간 만료 전 교환권 수신자에게 알림메시지가 발송됩니다.`}</MoreInfoContent>
          </MoreInfoWrapper>

          <CautionWrapper>
            <CautionText
              dangerouslySetInnerHTML={{ __html: selectedItem?.message.replaceAll(" - ", "\n - ") ?? "" }}
            ></CautionText>
          </CautionWrapper>
        </Wrapper>
        <ExchangeButton />
      </RelativeWrapper>
    </Layout>
  );
};

export default Item;

const RelativeWrapper = styled.div`
  position: relative;
  background-color: #ffffff;
  padding-bottom: 100px;
`;

const Wrapper = styled.div`
  min-height: 100vh;
  padding: 0 16px;
`;

const ItemImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 216px;
  margin-top: 71px;
`;

const ItemImage = styled.img`
  height: 100%;
  border-radius: 6px;
`;

const ItemInfoWrapper = styled.div`
  margin-top: 16px;
  padding-bottom: 29px;
  border-bottom: 1px solid var(--Gray-05, #2b2e33);
`;

const ItemBrand = styled.p`
  color: var(--Gray-04, #818282);

  /* Caption-M-14-Regular */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 23.8px */
  letter-spacing: -0.5px;
`;

const ItemName = styled.p`
  margin-bottom: 9px;

  color: var(--Gray-05, #2b2e33);

  /* SettingTitle-m-18-semibold */
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
  letter-spacing: -0.5px;
`;

const ItemPrice = styled.p`
  color: var(--Gray-05, #2b2e33);

  /* Title-XL-24-Bold */
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
  letter-spacing: -0.5px;
`;

const MoreInfoWrapper = styled.div`
  display: flex;
  gap: 24px;
  padding: 16px 8px;
  border-bottom: 1px solid var(--Gray-02, #dfdedd);
`;

const MoreInfoTitle = styled.p`
  color: var(--Gray-05, #2b2e33);
  white-space: nowrap;

  /* Caption-M-14-Regular */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 23.8px */
  letter-spacing: -0.5px;
`;

const MoreInfoContent = styled.p`
  color: var(--Gray-04, #818282);
  white-space: pre-line;

  /* Caption-M-14-Regular */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 23.8px */
  letter-spacing: -0.5px;
`;

const CautionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 16px;
`;

const CautionText = styled.p`
  color: var(--Gray-04, #818282);
  white-space: pre-line;

  /* caption-s-13-regular */
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 22.1px */
  letter-spacing: -0.5px;
`;
