import { AxiosError } from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { put, call, takeEvery } from "redux-saga/effects";
import { getUserInfo } from "../lib/api";

type UserResponseType = {
  points: number;
  surveyed: number;
  user_info: string;
};

export type UserStateType = {
  userToken: string;
  userName: string;
  surveyed: number;
  points: number;
};

const userInitialState: UserStateType = {
  userToken: "",
  userName: "",
  surveyed: 0,
  points: 0,
};

const userInfoSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    getUserInfo(state, action: PayloadAction<string>) {},
    setUserInfo(state, action: PayloadAction<UserStateType>) {
      const { payload } = action;
      state.userToken = payload.userToken;
      state.userName = payload.userName;
      state.points = payload.points;
      state.surveyed = payload.surveyed;
    },
  },
});

export const userActionsCreators = {
  ...userInfoSlice.actions,
};

function* getUserInfoWorker(action: any) {
  try {
    const userInfo: UserResponseType = yield call(getUserInfo, action.payload);
    let userInfoJson = JSON.parse(userInfo.user_info);

    // 계정 차단
    const blackList = ["+82 10-9134-1535"];
    if (blackList.includes(userInfoJson.phone_number) || userInfoJson.email === "sunhye1216@naver.com") {
      window.alert("잘못된 접근으로 이용 정지된 사용자입니다.");
      window.location.href = "http://pf.kakao.com/_BgxbUxl/chat";
      return;
    }

    const { nickname } = userInfoJson.profile;
    const { points, surveyed } = userInfo;
    yield put({
      type: "user/setUserInfo",
      payload: { userToken: action.payload, userName: nickname, points, surveyed },
    });
    yield put({
      type: "main/getItemCategories",
    });
  } catch (err: any) {
    // userInfo의 state가 302 이면 redirect
    if (err.isAxiosError) {
      const e: AxiosError = err;
      const redirectURL = e.response?.data;
      window.location.href = redirectURL;
    }
  }
}

export function* userSaga() {
  const { getUserInfo } = userActionsCreators;
  yield takeEvery(getUserInfo, getUserInfoWorker);
}

export const user = userInfoSlice.reducer;
