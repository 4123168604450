import React from "react";
import styled from "styled-components";
import { Shimmer } from "../Placeholder";
import { nameEllipsis } from "../../utils/utils";

type CategoryBoxPropsType = {
  id: number|null;
  img: string|null;
  name: string|null;
  onClick: any;
}

const CategoryBox = ({ id, img, name, onClick }: CategoryBoxPropsType) => {
  return (
    <BoxWrapper onClick={onClick} data-testid="brand-category" key={id}>
      {img && name ? (
        <ItemBox>
          <ImgBox>
            <img src={img} alt="brandImg" />
          </ImgBox>
          <NameBox>
            <p>{nameEllipsis(name)}</p>
          </NameBox>
        </ItemBox>
      ) : (
        <Shimmer mode="category">
          <ImgBoxPlaceholder />
          <NameBoxPlaceholder />
        </Shimmer>
      )}
    </BoxWrapper>
  );
};

export default CategoryBox;

const BoxWrapper = styled.div`
  width: 32%;
  height: 7em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  transition: all 0.25s linear;
  margin: 1%;
  padding: 7px 3px;
  &:nth-child(3n + 1) {
    margin: 1% 1% 1% 0;
  }
  &:nth-child(3n + 0) {
    margin: 1% 0 1% 1%;
  }
`;

const ImgBox = styled.div`
  height: 4em;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    width: 2.5em;
    height: 2.5em;
    /* margin-bottom: 1em; */
  }
`;

const ImgBoxPlaceholder = styled.div`
  height: 43px;
  width: 43px;
  background-color: #f0f0f0;
  margin: auto;
`;

const NameBox = styled.div`
  height: 3em;
  display: flex;
  word-break: keep-all;
  justify-content: center;
  align-items: center;

  p {
    width: 100%;
    font-size: 0.8em;
    text-align: center;
  }
`;

const NameBoxPlaceholder = styled.div`
  height: 7px;
  width: 69px;
  background-color: #f0f0f0;
  margin-top: 15px;
`;

const ItemBox = styled.div`
  &:hover {
    animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    @keyframes scale-up-center {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(1.1);
      }
    }
  }
`;
