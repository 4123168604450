import { useEffect } from "react";
import { connect } from "react-redux";
import Categories from "../../pages/Categories/Categories";
import { categoriesActionsCreators } from "../modules/categories";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { RootState } from "../modules/index";

const mapStateToProps = (state: RootState) => ({
  category: state.categories.category,
  itemCategories: state.main.itemCategories,
  isLoading: state.main.isLoading,
});

const mapDispatchToProps = {
  ...categoriesActionsCreators,
};

type CategoriesContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & RouteComponentProps;

const CategoriesContainer = ({
  itemCategories,
  category,
  getBrandsInItemCategories,
  isLoading,
}: CategoriesContainerProps) => {
  const { state } = useLocation<{ id: number }>();
  useEffect(() => {
    getBrandsInItemCategories(state.id);
  }, [state]);
  console.log("category: ", category);
  console.log("itemCategories: ", itemCategories);

  return <Categories category={category} itemCategories={itemCategories} isLoading={isLoading} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesContainer);
