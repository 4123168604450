import styled from "styled-components";
import { loadingSpinner } from "../assets";

function Loading({ isLoading }: { isLoading: boolean | undefined }) {
  if (isLoading) {
    return (
      <LoadingBox id="Loading">
        <LoadingSpinner>
          <img src={loadingSpinner} alt="spinner" />
        </LoadingSpinner>
      </LoadingBox>
    );
  } else {
    return null;
  }
}

export default Loading;

const LoadingSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 42rem) {
    width: 100%;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  img {
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
`;
const LoadingBox = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(43, 46, 51, 0.3);
  z-index: 999999;
`;
