import styled, { css } from "styled-components";

const categoryMode = {
  before: css`
    position: absolute;
    height: 20px;
    width: 120px;
    z-index: 1;
    animation: shimmer 1.25s infinite;
  `,
  keyframe: css`
    @keyframes shimmer {
      0% {
        transform: translate(-50px, -40px) rotate(315deg);
      }
      100% {
        transform: translate(30px, 70px) rotate(315deg);
      }
    }
  `,
};

const brandMode = {
  before: css`
    position: absolute;
    height: 30%;
    width: 100%;
    z-index: 1;
    animation: shimmer 1.75s infinite;
  `,
  keyframe: css`
    @keyframes shimmer {
      0% {
        transform: translate(-50%, -60%) rotate(315deg);
      }
      100% {
        transform: translate(20%, 40%) rotate(315deg);
      }
    }
  `,
};

const itemMode = {
  before: css`
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    animation: shimmer 1.75s infinite;
  `,
  keyframe: css`
    @keyframes shimmer {
      0% {
        transform: translate(-50%, -60%) rotate(315deg);
      }
      100% {
        transform: translate(20%, 40%) rotate(315deg);
      }
    }
  `,

}

type modeType = "category" | "brand" | "item";

const beforeCss = css`
  content: "";
  top: 10px;
  /* background: red; */
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  );
`;

export const Shimmer = styled.div<{ mode: modeType }>`
  ${(props) =>
    `
    overflow: hidden;
    position: relative;
    &::before {
    ${beforeCss}
    ${props.mode === "category" ? `${categoryMode.before}` : props.mode === "brand" ? `${brandMode.before}` : props.mode === "item" ? `${itemMode.before}` : ""}
  }
  
  ${props.mode === "category" ? `${categoryMode.keyframe}` : props.mode === "brand" ? `${brandMode.keyframe}` : props.mode === "item" ? `${itemMode.keyframe}` : ""}
  `}
`;
