import axios, { AxiosError } from "axios";
import { put, call, select } from "redux-saga/effects";
import { postOrder } from "./api";

export type KakaoLoginResponse = {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  refresh_token_expires_in: number;
};

export default function* postOrderSaga() {
  yield put({
    type: "main/setIsLoading",
    payload: true,
  });

  try {
    const { userToken, brandInfo, conItemId, itemName, expireAt, info } = yield select((state) => ({
      userToken: state.user.userToken,
      brandInfo: state.brand.brandInfo,
      conItemId: state.items.selectedItem.id,
      itemName: state.items.selectedItem.name,
      info: state.items.selectedItem.info,
      expireAt: state.items.selectedOptionValue.expireAt,
    }));
    const conItem = brandInfo.brandItems.filter((item: any) => item.id === conItemId)[0];
    const newConItem = { ...conItem, conCategory2: { imageUrl: brandInfo.imageUrl } };
    const newInfo = info.replace("·  쿠폰 사용과 관련한 불편은 카카오톡 채널 @니콘내콘 고객센터로 문의바랍니다.", "");

    // data = {info, expireAt, reducedPoints, conUrl, barcodeNumber}
    const { data } = yield call(postOrder, userToken, expireAt, newConItem, newInfo);

    yield put({
      type: "items/setPurchasedItem",
      payload: data,
    });

    yield put({
      type: "main/setIsLoading",
      payload: false,
    });

    // const test = `http://plus.kakao.com/talk/bot/${window.$talkbot}/%EA%B5%AC%EB%A7%A4%20%EC%99%84%EB%A3%8C%E2%80%8B%E2%80%8C`
    window.location.href = data.goto; // 패널몰 챗봇 채널로 이동
  } catch (error: any) {
    if (error.isAxiosError) {
      const e: AxiosError = error;
      if (e.response?.status === 403) {
        window.alert("결제에 실패했습니다. 포인트를 확인해주세요.​");
      } else if (e.response?.status === 500) {
        window.alert("해당 상품이 품절되었습니다. 잠시 후 다시 시도하시거나 새로운 상품을 ​선택해주세요.​");
      } else {
        window.alert(
          "문제가 발생하였습니다. \n잠시 후 다시 시도하시거나 홈화면 최하단 > 문의하기 버튼을 통해 ​문의해주세요.​​",
        );
      }
    }
    yield put({
      type: "main/setIsLoading",
      payload: false,
    });
  }
}
