export const priceToString = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const convertDate = (yymmdd, divider) => {
  const d = yymmdd; // YYMMDD
  const yy = d.substr(0, 2);
  const mm = d.substr(2, 2);
  const dd = d.substr(4, 2);
  const yyyy = +yy < 90 ? "20" + yy : "19" + yy;
  return yyyy + divider + mm + divider + dd;
};

export const discountPrice = (min, ori) => Math.floor((1 - min / ori) * 100);

export const changeUrl = (url) => {
  const splitPrev = url.split("/");
  splitPrev[0] = "https://";
  splitPrev[1] = "s3.ap-northeast-2.amazonaws.com//pocketsurvey.earlysloth";
  splitPrev[2] = "/images/public/ncnc/";
  const result = splitPrev.join("").replace("jpg", "svg");
  return result;
};

export function chunkString(str, length) {
  return str.match(new RegExp(".{1," + length + "}", "g"));
}

export const nameEllipsis = (name) => {
  if (name.length > 20) {
    return name.slice(0, 20) + "...";
  }
  return name;
};
