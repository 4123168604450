import axios, { AxiosResponse } from "axios";
import { changeUrl } from "utils/utils";
import { API } from "../../config";
import { CategoryType, UserInfoType } from "../../redux/modules/types";

// 유저 정보 조회
export const getUserInfo = async (userToken: string) => {
  let data: AxiosResponse<UserInfoType> = await axios.get(`${API}/authorize`);
  return data.data;
};

// 아이템 대분류 조회
// ex) 빵, 아이스크림 / 피자, 햄버거, 치킨 ...
export const getItemCategories: any = async () => {
  const data: AxiosResponse<{ conCategory1s: CategoryType[] }> = await axios.get(`${API}/con-category1s`);
  return data.data.conCategory1s;
};

// 메인 카테고리 이미지 변경
export const changeCategoryImage = async (originalData: any) => {
  return originalData;
  // const data: CategoryType[] = [...originalData];
  // for (let i = 0; i < data.length; i++) {
  //   const res: AxiosResponse<string> = await axios.get(changeUrl(data[i].imageUrl));
  //   if (res.status === 200 && res.config.url) {
  //     data[i].imageUrl = res.config.url;
  //   }
  // }
  // return data.filter((item) => item.id !== 128);
};

// 대분류에 속한 브랜드 조회
// ex) 뚜레쥬르, 던킨도너츠 ...
export const getBrandsInItemCategories: any = async (id: number) => {
  const res: AxiosResponse = await axios.get(`${API}/con-category1s/${id}/nested/`);
  return res.data;
};

// 개별 아이템 조회
export const getItem = (id: string) => axios.get(`${API}/con-items/${id}`);

// 아이템 주문
export const postOrder = async (user: string, expireAt: string, conItem: any, info: any) => {
  const res: AxiosResponse = await axios.post(`${API}`, {
    user,
    expireAt,
    conItem,
    info,
  });

  return res;
};
