import React from "react";
import styled from "styled-components";
import { priceToString } from "../../../utils/utils";
import useUserStore from "stores/user";
import { shallow } from "zustand/shallow";

const UserInfo = () => {
  const { points, surveyed, userName } = useUserStore(
    (state) => ({
      points: state.points,
      userName: state.userName,
      surveyed: state.surveyed,
    }),
    shallow,
  );

  return (
    <UserInfoWrapper data-testid="user-info">
      <UserInfoBox>
        <UserNameBox>
          <UserName>{userName ? userName : "-"}님</UserName>
          <UserNameRest>의 포인트</UserNameRest>
        </UserNameBox>
        <UserPoint>{priceToString(points)}P</UserPoint>
        <UserParticipation>현재까지 {surveyed}건의 설문을 참여했습니다.</UserParticipation>
      </UserInfoBox>
    </UserInfoWrapper>
  );
};

export default UserInfo;

const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserInfoBox = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
`;

const UserNameBox = styled.p``;

const UserName = styled.span`
  color: var(--Gray-05, #2b2e33);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: -0.5px;
`;

const UserNameRest = styled.span`
  color: var(--Gray-04, #818282);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: -0.5px;
`;

const UserPoint = styled.div`
  margin-bottom: 9px;

  color: var(--bold-yellow-07, #f2ab28);
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.5px;
`;
const UserParticipation = styled.div`
  color: var(--Gray-04, #818282);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.5px;
`;
