import {useEffect} from 'react';
import styled from "styled-components";
import { MainStateType } from '../../../redux/modules/main'; 
import { useLocation, useHistory } from "react-router-dom";

type ItemCategoriesPropsType = Pick<MainStateType, 'itemCategories'>;

const ItemCategories = ({ itemCategories }: ItemCategoriesPropsType) => {
  const history = useHistory();
  const { state } = useLocation<{id: number}>(); 
  const subNavToCategories = (id : number, name : string) => {
    history.push({
      pathname: `/categories/${id}`,
      state: { id: id, name: name },
    });
  };
  useEffect(()=>{
    const selectedCategory = document.querySelector(`.itemCategory${state.id}`);
    selectedCategory?.scrollIntoView(true)
  },[])
  return (
    <>
      {itemCategories ? itemCategories.map((title, idx) => {
        return (
          <ItemCategory
          className={`itemCategory${title.id}`}
          onClick={() => {
            if(title.id) {
              subNavToCategories(title.id, title.name)
            }
            ;
          }} 
          key={title.id}
          >
            <CategoryTitle key={idx} underLine={state.id === title.id}>
              {title.name}
            </CategoryTitle>
          </ItemCategory>
        );
      }) : null}
    </>
  );
};

export default ItemCategories;

const ItemCategory = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-right: 0.875em;
`;

const CategoryTitle = styled.button<{underLine : boolean}>`
  width: 100%;
  height: 2.5em;
  border: none;
  outline: none;
  font-size: 1em;
  border-bottom: 0.15em solid ${(props) => (props.underLine ? ({ theme }) => theme.colors.yellow : "none")};
  color: ${(props) => (props.underLine ? ({ theme }) => theme.colors.yellow : "black")};
  background-color: white;
`;
