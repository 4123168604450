import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as RewardArrow } from "../../../assets/images/rewardArrow.svg";

export type QuestionListType = {
  id: number;
  q: string;
  a: React.ReactNode;
}[];

const QuestionList = () => {
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const [toggle, setToggle] = useState<boolean>(true);

  const selectDropdown = (idx: number) => {
    if (focusedIndex === idx) {
      setToggle(!toggle);
    } else {
      setFocusedIndex(idx);
      setToggle(true);
    }
  };

  const qList: QuestionListType = [
    {
      id: 1,
      q: "실수로 잘못 구매한 쿠폰을 환불받을 수 있나요?",
      a: (
        <div>
          {`구매하신 쿠폰은 프로모션 상품 쿠폰입니다.`}
          <br />
          {`추후 문제가 발생할 우려가 있어 바코드 이미지 노출 이후에는 단순 변심, ​주문 실수 등의 사유로 환불 및 교환이 불가능해요.`}
        </div>
      ),
    },
    {
      id: 2,
      q: "구매한 쿠폰이 도착하지 않았어요",
      a: (
        <div>
          {`구매하신 쿠폰은 "포켓단" 이름의 채널로 발송됩니다.`}
          <br />
          {`카카오톡 채팅 목록에서 포켓단 채널이 보이지 않을 경우, 홈화면 최하단 > "문의하기" 버튼을 눌러 문의해주세요.`}
          <br />
          {`문의하실 때에는 정확한 확인 후 도움을 드릴 수 있도록, [1. 휴대폰번호 / 2. 카카오톡 닉네임  / 3. 쿠폰 구입시간] 을 알려주세요.`}
        </div>
      ),
    },
    {
      id: 3,
      q: "이미 사용한 쿠폰이라고 나와요",
      a: (
        <div>
          {`[쿠폰 판매 시도 시]`}
          <br />
          {`포켓몰에서 구매하신 쿠폰은 "제휴" 쿠폰입니다.`}
          <br />
          {`니콘내콘과 같은 쿠폰 중고 판매 서비스에서는 '이미 사용한 쿠폰'이라는 안내가 나오며 판매되지 않습니다.`}
          <br />
          {`판매가 불가능한 제휴 쿠폰이니, 직접 사용해주세요.`}
          <br />
          <br />
          {`[쿠폰 직접 사용 시]`}
          <br />
          {`쿠폰 사용처 방문 시, 이미 사용한 쿠폰으로 나오시는 경우에는 홈화면 최하단 > "문의하기" 버튼을 눌러 문의해주세요.`}
          <br />
          {`문의하실 때에는 정확한 확인 후 도움을 드릴 수 있도록, [1. 휴대폰번호 / 2. 카카오톡 닉네임  / 3. 쿠폰 사용 지점명(예 : GS25종로인사점)]을 알려주세요.`}
        </div>
      ),
    },
    {
      id: 4,
      q: "맛보기 설문이 오지 않아요",
      a: (
        <div>
          {`맛보기 설문 알림톡은 1시간 내외로 발송됩니다.`}
          <br />
          {`혹시나 시간이 지나도 맛보기 설문이 오지 않는다면, 아래의 링크를 클릭하여 참여해주세요.`}
          <br />
          <br />
          <a href="https://link.pocketsurvey.co.kr/p/T6hw7-4">맛보기 설문 바로가기</a>
        </div>
      ),
    },
    {
      id: 5,
      q: "포인트 유효기간은 언제까지인가요?",
      a: (
        <div>
          {`포켓단 포인트의 유효기간은 없습니다!`}
          <br />
          {`원하시는 만큼 포인트를 모아, 원하시는 쿠폰으로 구매해주세요.`}
        </div>
      ),
    },
    {
      id: 6,
      q: "궁금한 부분은 어디로 문의하면 되나요?​",
      a: <div>{`홈화면 최하단 > "문의하기" 버튼을 통해 궁금한 사항을 문의해주세요.`}</div>,
    },
  ];

  return (
    <QWrapper data-testid="qa-container">
      <QATitle>자주 묻는 질문</QATitle>
      {qList?.map((quest, idx) => {
        return (
          <Question key={idx} data-testid="question">
            <QuestionTitleBox onClick={() => selectDropdown(idx)}>
              <QuestionTitle>
                <div>Q.</div>
                <div style={{ wordBreak: "keep-all" }}>{quest.q}</div>
              </QuestionTitle>
              <RewardArrow className={focusedIndex === idx && toggle ? "arrow up" : "arrow"} />
            </QuestionTitleBox>
            {focusedIndex === idx && toggle && <QuestionContents>{quest.a}</QuestionContents>}
          </Question>
        );
      })}
    </QWrapper>
  );
};

export default QuestionList;

const QWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  margin-top: 4px;
  padding: 28px 20px;
`;

const QATitle = styled.div`
  margin-bottom: 14px;
  font-size: 16px;

  color: var(--Gray-05, #2b2e33);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.5px;
`;

const Question = styled.div`
  width: 100%;
  height: fit-content;
  font-size: 14px;
  &:not(:last-child) {
    margin-bottom: 7px;
  }
`;

const QuestionTitleBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background-color: #f0f0f0;
  padding: 14px 20px;
  word-break: keep-all;
  font-size: 14px;

  .arrow {
    width: 1em;
    height: 1em;
    transform: rotate(90deg);
    &.up {
      transform: rotate(-90deg);
    }
  }
`;

const QuestionTitle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  color: #2b2e33;
  font-family: "Pretendard";
  font-weight: 500;

  div:first-child {
    margin-right: 0.625em;
  }
`;

const QuestionContents = styled.div`
  width: 100%;
  word-break: keep-all;
  padding: 5px 20px 14px 20px;
  font-size: 14px;
  line-height: 1.5rem;
  background-color: ${({ theme }) => theme.colors.gray01};
  color: #2b2e33;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: -5px;
`;
