import { BrandItemType } from "redux/modules/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { put, takeEvery, select } from "redux-saga/effects";
import { BrandType } from "./types";

type BrandStateType = {
  brandInfo: BrandType;
};

const brandInitialState: BrandStateType = {
  brandInfo: {
    id: null,
    name: "",
    imageUrl: "",
    categoryId: null,
    brandItems: [],
  },
};

const brandSlice = createSlice({
  name: "brand",
  initialState: brandInitialState,
  reducers: {
    getBrandItems(state, action: PayloadAction<{ targetBrandId: number }>) {},
    setBrandItems(state, action) {
      state.brandInfo.id = action.payload.id;
      state.brandInfo.name = action.payload.name;
      state.brandInfo.imageUrl = action.payload.imageUrl;
      state.brandInfo.categoryId = action.payload.conCategory1Id;
      state.brandInfo.brandItems = action.payload.conItems;
    },
  },
});

export const brandActionsCreators = {
  ...brandSlice.actions,
};

function* getBrandItemsWorker(action: PayloadAction<{ targetBrandId: number }>) {
  const { targetBrandId } = action.payload;

  const { allBrandItems } = yield select((state) => ({
    allBrandItems: state.categories.category.brands,
  }));
  const targetBrandItems: {
    id: number;
    conItems: BrandItemType[];
    conCategory1Id: number;
    imageUrl: string;
    name: string;
  } = allBrandItems.filter((item: any) => item.id === targetBrandId)[0];
  const filteredItems: BrandItemType[] = targetBrandItems.conItems.filter(
    (item: any) => item.originalPrice >= 3000,
  );

  const brandItems = {
    ...targetBrandItems,
    conItems: filteredItems,
  };

  yield put({
    type: "brand/setBrandItems",
    payload: brandItems,
  });
}

export function* brandSaga() {
  const { getBrandItems } = brandActionsCreators;
  yield takeEvery(getBrandItems, getBrandItemsWorker);
}

export const brand = brandSlice.reducer;
